/* Marker tweaks */
.mapboxgl-popup-close-button {
    display: none;
  }
  
.mapboxgl-popup-content {
  font: 400 15px/22px 'Source Sans Pro', 'Helvetica Neue', sans-serif;
  padding: 0;
  width: 180px;
}

.mapboxgl-popup-content h3 {
  background: #91c949;
  color: #fff;
  margin: 0;
  padding: 10px;
  border-radius: 3px 3px 0 0;
  font-weight: 700;
  margin-top: -10px;
  margin-left: -10px;
  margin-right: -10px;
}

.mapboxgl-popup-content h4 {
  margin: 0;
  padding: 10px;
  font-weight: 400;
}

.mapboxgl-popup-content div {
  padding: 10px;
}

.mapboxgl-popup-anchor-top > .mapboxgl-popup-content {
  margin-top: 0px;
}

.mapboxgl-popup-anchor-top > .mapboxgl-popup-tip {
  border-bottom-color: #91c949;
}


.listings {
  height: 100%;
  overflow: auto;
  padding-bottom: 60px;
}

.listings .item {
  border-bottom: 1px solid #eee;
  padding: 10px;
  text-decoration: none;
}

.listings .item:last-child { border-bottom: none; }

.listings .item .title {
  display: block;
  color: #00853e;
  font-weight: 700;
}

.listings .item .title small { font-weight: 400; }

.listings .item.active .title,
.listings .item .title:hover { color: #8cc63f; }

.listings .item.active {
  background-color: #f8f8f8;
}

::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  border-left: 0;
  background: rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar-track {
  background: none;
}

::-webkit-scrollbar-thumb {
  background: #00853e;
  border-radius: 0;
}

.marker {
  border: none;
  cursor: pointer;
  height: 56px;
  width: 56px;
  background-image: url(../assets/marker.png);
}
.mapboxgl-popup {
  padding-bottom: 50px;
}

.tag {
  padding: 4px 8px;
  border-radius: 5px;
  font-size: smaller;
  margin-left: .2rem;

  
}
.orange{
   background-color: #FAEBDD;
  }

.default{
   background-color: #CECDCA;
  }

.gray{
  background-color: #e3e2e0;
  }  

.brown{
  background-color: #efe0da;
  }

.yellow{
  background-color: #d3e5ef;
  }

.green{
  background-color: #dbeddb;
  } 

.blue{
  background-color: #d3e5ef;
  } 

.purple{
  background-color: #EAE4F2;
  } 

.pink{
  background-color: #f4e0e9;
  }   

.red{
  background-color: #FBE4E4;
  }